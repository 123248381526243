/* eslint-disable no-undef */
import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import { Separator } from '@atomic/atm.separator';
import { ContentSection } from '@components/content.section';
import { HeroSection } from '@components/hero.section';
import { HowItWorksSection } from '@components/how-it-works.section';
import { NewsletterSection } from '@components/newsletter.section';
import { PricingSection } from '@components/pricing.section';
import { TestimonialsSection } from '@components/testimonials.section';

export const LandingPageTemplate: React.FC = () => {
  const {
    hero,
    courses_section: coursesSection,
    testimonials,
    how_it_works: howItWorks,
    newsletter,
    plans
  } = useStaticQuery<GatsbyTypes.getLandingPageQuery>(graphql`
    query getLandingPage {
      allMarkdownRemark(
        filter: { frontmatter: { title: { eq: "Landing page" } } }
      ) {
        edges {
          node {
            frontmatter {
              hero {
                title
                description
                hero_image
              }
              courses_section {
                title
                description
                courses {
                  image
                  title
                  description
                }
              }
              testimonials {
                profile
                author
                testimonial
                office
              }
              how_it_works {
                title
                description
                sections {
                  image
                  title
                  name
                }
              }
              plans {
                title
                description
                plans {
                  plan_name
                  plan_type
                  description
                  installments
                  is_recommended
                  price
                  url
                  features {
                    feature
                  }
                }
              }
              newsletter {
                image
                title
                description
              }
            }
          }
        }
      }
    }
  `).allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <>
      <HeroSection data={hero} />
      <Separator type="XXLarge" />
      <ContentSection data={coursesSection} />
      <TestimonialsSection data={testimonials} />
      <HowItWorksSection data={howItWorks} />
      <Separator type="XXLarge" />

      {process.env.GATSBY_SHOW_PLAN === 'true' && <PricingSection data={plans} />}
      {/* <NewsletterSection data={newsletter} /> */}
    </>
  );
};
